import { atom, useRecoilValue, SetterOrUpdater, useSetRecoilState } from 'recoil';
import { ISearchInputLocation, ISearchInputTerm, SearchInputTermTypes } from '../global-search.context';

interface IGlobalSearchValues {
  mainSearchVisible: boolean;
  location: ISearchInputLocation;
  tenantKey: string;
  term: ISearchInputTerm;
}

interface ISetGlobalSearch {
  setMainSearchVisible: SetterOrUpdater<boolean>;
  setLocation: SetterOrUpdater<ISearchInputLocation>;
  setTenantKey: SetterOrUpdater<string>;
  setTerm: SetterOrUpdater<ISearchInputTerm>;
}

const mainSearchVisibleState = atom<boolean>({
  key: 'mainSearchVisible',
  default: true,
});

const locationState = atom<ISearchInputLocation>({
  key: 'location',
  default: {
    name: '',
    placeId: ''
  }
});

const tenantKeyState = atom<string>({
  key: 'tenantKey',
  default: ''
});

const termState = atom<ISearchInputTerm>({
  key: 'term',
  default: {
    value: '',
    type: SearchInputTermTypes.TEXT,
  }
});

export const globalSearchAtoms = {
  location: locationState,
};

export const useGlobalSearchValues = (): IGlobalSearchValues => ({
  mainSearchVisible: useRecoilValue(mainSearchVisibleState),
  location: useRecoilValue(locationState),
  tenantKey: useRecoilValue(tenantKeyState),
  term: useRecoilValue(termState),
});

export const useSetGlobalSearch = (): ISetGlobalSearch => ({
  setMainSearchVisible: useSetRecoilState(mainSearchVisibleState),
  setLocation: useSetRecoilState(locationState),
  setTenantKey: useSetRecoilState(tenantKeyState),
  setTerm: useSetRecoilState(termState),
});
