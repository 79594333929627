import styled from '@emotion/styled';
import { theme, FontPrimaryRegular } from '@digando/react-component-library';

interface IWidgetContainerProps {
  noLeftSpacing?: boolean;
}

export const WidgetContainer = styled.div<IWidgetContainerProps>`
  color: ${theme.isInverted ? theme.white : theme.black};

  ${FontPrimaryRegular};
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: ${(p): string => p.noLeftSpacing ? '0' : '3.4vw'};
  white-space: nowrap;

  * {
    transition: color 0.3s ease;
  }

  > button,
  > a {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
  }

  span {
    display: inline-block;
  }

  button,
  a {
    color: inherit;
    font: inherit;

    &:hover {
      color: ${theme.isInverted ? theme.primaryColorInverted : theme.primaryColor};
      cursor: pointer;
    }
  }

  &:hover {
    --text-color: ${theme.primaryColor};
  }

  @media print {
    display: none;
  }
`;
