import { size } from '@digando/react-component-library';
import React from 'react';
import { isSearchTermOverlayOpenState } from './states/search-term-overlay.states';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import dynamic from 'next/dynamic';
import { Spin } from 'antd';
import { DigandoOverlayComponentLoader, DigandoOverlayWrapper } from '../digando-overlay';

const FilterOverlay = dynamic(() => import('./components/search-term-overlay').then((mod) => mod.SearchTermOverlay), {
  ssr: false,
  loading: () => <DigandoOverlayComponentLoader>
    <Spin />
  </DigandoOverlayComponentLoader>,
});

export const SearchTermOverlayLoader = () => {
  const isSearchTermOverlayOpen = useRecoilValue(isSearchTermOverlayOpenState);
  const setIsSearchTermOverlayOpen = useSetRecoilState(isSearchTermOverlayOpenState);

  // Block body scrolling when isOpen is true and check if document exists.
  React.useEffect(() => {
    if (document) {
      if (isSearchTermOverlayOpen && 769 >= window?.innerWidth) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  }, [isSearchTermOverlayOpen]);

  React.useEffect(() => {
    // Close filter overlay on window resize and if window is bigger than 768px.
    const handleResize = (): void => {
      if (window.innerWidth > parseInt(size.tablet)) {
        setIsSearchTermOverlayOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <DigandoOverlayWrapper id={'global-search-term-overlay'}  isOpen={isSearchTermOverlayOpen}>
      {true === isSearchTermOverlayOpen && <FilterOverlay />}
    </DigandoOverlayWrapper>
  );
};

