export enum DrawerStep {
    NONE = 'none',
    SIGN_IN = 'signIn',
    SIGN_UP = 'signUp',
    FORGOT_PASSWORD = 'forgotPassword',
    RESET_PASSWORD = 'resetPassword',
  }

export enum SignUpStep {
    SIGN_UP_COMPANY = 'signUpCompany',
    SIGN_UP_USER = 'signUpUser',
    SIGN_UP_FINISH = 'signUpFinish',
    SIGN_UP_DONE = 'signUpDone',
  }

export type DrawerModes = 
| DrawerStep.NONE
| DrawerStep.SIGN_IN
| DrawerStep.SIGN_UP
| DrawerStep.FORGOT_PASSWORD
| DrawerStep.RESET_PASSWORD;

export type SignUpModes =
| SignUpStep.SIGN_UP_COMPANY
| SignUpStep.SIGN_UP_USER
| SignUpStep.SIGN_UP_FINISH
| SignUpStep.SIGN_UP_DONE
