/**
 * t('form:error.required')
 */
import { Error } from '@digando/common-ui';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'i18next/typescript/t';

const validationMapping: Record<string, (value: string, t: TFunction<[string, string], undefined>) => Error> = {
  email: (value: string, t: TFunction) => {
    // When the key is email, we need to validate the value if it's an email.
    // We can use a regex to validate the email.
    // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(value)) {
      return {
        key: 'email',
        isValid: false,
        errorCode: t('form:error.email-is-not-valid'),
      };
    }

    return {
      key: '',
      isValid: true,
      errorCode: '',
    };
  },
  required: (value: string, t: TFunction) => {
    if (!value) {
      return {
        key: 'required',
        isValid: false,
        errorCode: 'required',
      };
    }

    return {
      key: 'required',
      isValid: true,
      errorCode: '',
    };
  },
};

export const useValidator = (value: string, type: string): Error => {
  const [t] = useTranslation(['form', 'common']);

  return validationMapping[type](value, t);
};
