import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ContentContainerMax } from '../../layout/container';
import {
  Icon,
  theme,
  spacingBottom,
  spacingLeft,
  spacingTop, spacingTopBottom, spacingCalculator,
} from '@digando/react-component-library';
import Link from 'next/link';
import { mediaQueries } from '../../styles/media-queries';
import DigandoLogo from '../../svg/PlatformLogo';
import { Newsletter } from '../newsletter/newsletter';
import { isFeatureFlagEnabled } from '../../lib/environment';
import { FooterNavigation } from './footer-navigation';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const PageFooter: FC = () => {
  return (
    <PageFooterContainer>
      <TopBar />
      <PageFooterInner>
        <ContainerLeft>
          <LogoSocialWrapper>
            <DigandoLogoWrapper>
              <Link href={'/'} passHref prefetch={false} aria-label='digando.com'>
                <DigandoLogo />
              </Link>
            </DigandoLogoWrapper>
            {isFeatureFlagEnabled('isSocialMediaEnabled') && (
              <SocialLinks>
                <SocialLink href={publicRuntimeConfig.socialMedia.facebookUrl} target={'_blank'} rel='noopener'>
                  <Icon size={'32px'} icon={'facebook'} color={theme.white} /> <SrOnly>Facebook</SrOnly>
                </SocialLink>
                <SocialLink href={publicRuntimeConfig.socialMedia.instagramUrl} target={'_blank'} rel='noopener'>
                  <Icon size={'32px'} icon={'insta'} color={theme.white} /> <SrOnly>Instagram</SrOnly>
                </SocialLink>
                <SocialLink href={publicRuntimeConfig.socialMedia.linkedinUrl} target={'_blank'} rel='noopener'>
                  <Icon size={'32px'} icon={'linkedin'} color={theme.white} /> <SrOnly>LinkedIn</SrOnly>
                </SocialLink>
              </SocialLinks>
            )}
          </LogoSocialWrapper>
          {isFeatureFlagEnabled('isNewsletterEnabled') && (
            <NewsletterContainer>
              <Newsletter identifier={'page-footer'} />
            </NewsletterContainer>
          )}
        </ContainerLeft>

        <FooterNavigation />

      </PageFooterInner>
    </PageFooterContainer>
  );
};

const PageFooterContainer = styled.footer`
  width: 100%;
  background-color: ${theme.black};
  color: ${theme.white};

  @media print {
    display: none;
  }
`;

const PageFooterInner = styled(ContentContainerMax)`
  ${spacingTop(7)}
  ${spacingBottom(6)}
`;

const TopBar = styled.div`
  width: 100%;
  height: var(--spacing-steps);
  background-color: ${theme.primaryColor};
`;

const ContainerLeft = styled.div`
  grid-column: auto / span 12;

  @media ${mediaQueries.tablet} {
    order: 2;
  }

  @media ${mediaQueries.laptop} {
    grid-column: auto / span 5;
    order: 0;
  }
`;

const LogoSocialWrapper = styled.div`

  @media ${mediaQueries.tablet} {
    ${spacingTopBottom(5)};
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: ${spacingCalculator((5))};
  }

  @media ${mediaQueries.laptop} {
    ${spacingTop(0)};
  }
`;

const DigandoLogoWrapper = styled.div`
  ${spacingBottom(7)};

  @media ${mediaQueries.tablet} {
    ${spacingBottom(0)};
    grid-column: auto / span 3;
  }

  @media ${mediaQueries.laptop} {
    grid-column: auto / span 4;
  }

  img {
    width: auto !important;
    height: 30px;
    vertical-align: bottom;

    @media ${mediaQueries.tablet} {
      width: 100%;
      padding: 0;
    }
  }
`;

const SocialLinks = styled.div`
  ${spacingBottom(7)};

  @media ${mediaQueries.tablet} {
    ${spacingBottom(0)};
    grid-column: auto / span 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${mediaQueries.laptop} {
    justify-content: left;
  }
`;

const SocialLink = styled.a`
  ${spacingLeft(5)};

  &:first-of-type {
    ${spacingLeft(0)};
  }

  svg path {
    transition: ${theme.textColorHoverTransition};
    fill: ${theme.white};
  }

  &:hover svg path {
    fill: ${theme.primaryColor};
  }
`;

const NewsletterContainer = styled.div`
  ${spacingBottom(10)};

  @media ${mediaQueries.laptop} {
    ${spacingBottom(0)};
  }
`;

const SrOnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

export default React.memo(PageFooter, () => true);
