import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

interface IAvailabilityContextProps {
  loadedDateTime: string | null;
  countProducts: number;
  isAvailabilityCheckVisible: boolean;
  setIsAvailabilityCheckVisible: (isAvailabilityCheckVisible: boolean) => void;
  setLoadedDateTime: (date: string) => void;
  reload: () => void;
  addReloadCallback: (callback: () => void) => void;
  setCountProducts: (count: number) => void;
}

const AvailabilityContext = createContext<IAvailabilityContextProps>({
  loadedDateTime: null,
  countProducts: 0,
  isAvailabilityCheckVisible: false,
  setIsAvailabilityCheckVisible: (): void => {
    // eslint-disable-next-line no-console
    console.error('Availability Context not ready yet.');
  },
  setLoadedDateTime: () => {
    // eslint-disable-next-line no-console
    console.error('Availability Context not ready yet.');
  },
  reload: (): void => {
    // eslint-disable-next-line no-console
    console.error('Availability Context not ready yet.');
  },
  addReloadCallback: (): void => {
    // eslint-disable-next-line no-console
    console.error('Availability Context not ready yet.');
  },
  setCountProducts: (): void => {
    // eslint-disable-next-line no-console
    console.error('Availability Context not ready yet.');
  },
});

interface IAvailabilityWrapperProps {
  ssrTotalProducts: number;
  ssrLoadedDateTime: string;
  children: React.ReactNode;
}

export const AvailabilityWrapper: FC<IAvailabilityWrapperProps> = (props) => {
  const { children } = props;
  const router = useRouter();

  const [loadedDateTime, setLoadedDateTime] = useState<string | null>(props.ssrLoadedDateTime);
  const [callback, setCallback] = useState<{ cb: (() => void) | null }>({ cb: null });
  const [countProducts, setCountProducts] = useState<number>(props.ssrTotalProducts);
  const [isAvailabilityCheckVisible, setIsAvailabilityCheckVisible] = useState<boolean>('/baumaschinen-mieten/[[...categories]]' === router.route);

  useEffect(() => {
    setIsAvailabilityCheckVisible('/baumaschinen-mieten/[[...categories]]' === router.route);
  }, [router.route]);

  const reload = useCallback((): void => {
    if (callback.cb) {
      callback.cb();
    }
  }, [callback]);

  const addReloadCallback = (cb: (() => void) | null): void => {
    setCallback({ cb });
  };

  return (
    <>
      <AvailabilityContext.Provider value={{
        loadedDateTime,
        countProducts,
        isAvailabilityCheckVisible,
        setLoadedDateTime,
        setCountProducts,
        setIsAvailabilityCheckVisible,
        reload,
        addReloadCallback,
      }}>
        {children}
      </AvailabilityContext.Provider>
    </>
  );
};

export function useAvailabilityContext(): IAvailabilityContextProps {
  return useContext(AvailabilityContext);
}
