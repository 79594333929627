import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { GridLayout } from './grid';
import React, { FC } from 'react';
import { mediaQueries } from '../styles/media-queries';

interface IContentContainerMaxProps extends React.HTMLAttributes<HTMLElement> {
  noMobileSpacing?: boolean;
  carouselFix?: boolean;
  children: React.ReactNode;
}

interface IContentContainerRegularProps extends React.HTMLAttributes<HTMLElement> {
  noMobileSpacing?: boolean;
}

interface IContentContainerMaxStyledProps extends IContentContainerMaxProps {
  contentType: 'content' | 'default';
}

export const ContentContainerPageHeader = styled.section`
  display: flex;
  align-items: center;
  padding-right: env(safe-area-inset-right, 2.5vw);
  padding-left: env(safe-area-inset-left, 2.5vw);
  max-width: 95vw;
  margin-left: auto;
  margin-right: auto;
`;

export const ContentContainerMax: FC<IContentContainerMaxProps> = ({ children, ...props }) =>  {
  const theme = useTheme();

  return (
    <ContentContainerMaxStyling {...props} contentType={theme?.contentType ?? 'default'}>
      {children}
    </ContentContainerMaxStyling>
  );
};

const ContentContainerMaxStyling = styled.section<IContentContainerMaxStyledProps>`
  ${GridLayout};
  ${(p): string => p.carouselFix ? 'grid-template-columns: repeat(12, minmax(0, 1fr));' : ''};
  width: 100vw;
  max-width: ${(p): string => 'default' === p.contentType ? '1424px' : '1184px'};

  @media all and (max-width: ${(p): string => 'default' === p.contentType ? '1600px' : '1360px'}) {
    width: 89vw;
    max-width: none;
  }

  @media all and (max-width: 900px) {
    width:95vw;
    padding-right: env(safe-area-inset-right, 2.5vw);
    padding-left: env(safe-area-inset-left, 2.5vw);
  }

  @media all and (max-width: 767px) {
    width: ${(p: IContentContainerMaxProps): string => p.noMobileSpacing ? '100vw' : '95vw'};
    padding-right: env(safe-area-inset-right, 2.5vw);
    padding-left: env(safe-area-inset-left, 2.5vw);
  }
`;

export const ContentContainerRegular = styled.section<IContentContainerRegularProps>`
  ${GridLayout};
  width: 100vw;
  max-width: 1050px;

  @media all and (max-width: 1204px) {
    width: ${(p): string => p.noMobileSpacing ? '100vw' : '89vw'};
    max-width: none;
    grid-gap: 3vw;
  }

  @media all and (max-width: 900px) {
    padding-right: env(safe-area-inset-right, 2.5vw);
    padding-left: env(safe-area-inset-left, 2.5vw);
    max-width: ${(p): string => p.noMobileSpacing ? '100vw' : '95vw'};
  }
`;

export const ContentContainerSmall = styled.div`
  max-width: 320px;
  margin: 0 auto;

  @media ${mediaQueries.tablet} {
    max-width: 650px;
  }
`;
