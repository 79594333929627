import { size } from '@digando/react-component-library';
import React from 'react';
import { isSearchLocationOverlayOpenState } from './states/search-location-overlay.states';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import dynamic from 'next/dynamic';
import { Spin } from 'antd';
import { DigandoOverlayComponentLoader, DigandoOverlayWrapper } from '../digando-overlay';

const SearchLocationOverlay = dynamic(() => import('./components/search-location-overlay').then((mod) => mod.SearchLocationOverlay), {
  ssr: false,
  loading: () => <DigandoOverlayComponentLoader>
    <Spin />
  </DigandoOverlayComponentLoader>,
});

export const SearchLocationOverlayLoader = () => {
  const isSearchLocationOverlayOpen = useRecoilValue(isSearchLocationOverlayOpenState);
  const setIsSearchLocationOverlayOpen = useSetRecoilState(isSearchLocationOverlayOpenState);

  // Block body scrolling when isOpen is true and check if document exists.
  React.useEffect(() => {
    if (document) {
      if (isSearchLocationOverlayOpen && 769 >= window?.innerWidth) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  }, [isSearchLocationOverlayOpen]);

  React.useEffect(() => {
    // Close filter overlay on window resize and if window is bigger than 768px.
    const handleResize = (): void => {
      if (window.innerWidth > parseInt(size.tablet)) {
        setIsSearchLocationOverlayOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <DigandoOverlayWrapper id={'global-search-location-overlay'} isOpen={isSearchLocationOverlayOpen}>
      {true === isSearchLocationOverlayOpen && <SearchLocationOverlay />}
    </DigandoOverlayWrapper>
  );
};

