import React, { FC } from 'react';
import styled from '@emotion/styled';
import {
  spacingLeftRight,
  spacingTopBottom,
  theme,
} from '@digando/react-component-library';
import { useAuthContext } from '../../store/auth.context';
import dynamic from 'next/dynamic';
import { DrawerStep } from './interfaces/drawer.interfaces';
import { SignUpWrapper } from '../../store/sign-up.context';
import { Backdrop } from '../elements/Backdrop/Backdrop';
import { Spin } from 'antd';

const importMapping: { [key: string]: { Header: React.ComponentType; Content: React.ComponentType; } } = {
  signIn: {
    Header: dynamic(() =>
        import('./layers/sign-in/sign-in').then((mod) => mod.Header),
      { ssr: false, loading: () => <Spin /> },
    ),
    Content: dynamic(() =>
        import('./layers/sign-in/sign-in').then((mod) => mod.SignIn),
      { ssr: false, loading: () => <Spin /> },
    ),
  },
  signUp: {
    Header: dynamic(() =>
        import('./layers/sign-up/sign-up').then((mod) => mod.Header),
      { ssr: false, loading: () => <Spin /> },
    ),
    Content: dynamic(() =>
        import('./layers/sign-up/sign-up').then((mod) => mod.SignUp),
      { ssr: false, loading: () => <Spin /> },
    ),
  },
  forgotPassword: {
    Header: dynamic(() =>
        import('./layers/sign-in/forgot-password').then((mod) => mod.Header),
      { ssr: false, loading: () => <Spin /> },
    ),
    Content: dynamic(() =>
        import('./layers/sign-in/forgot-password').then((mod) => mod.ForgotPassword),
      { ssr: false, loading: () => <Spin /> },
    ),
  },
  resetPassword: {
    Header: dynamic(() =>
        import('./layers/sign-in/reset-password').then((mod) => mod.Header),
      { ssr: false, loading: () => <Spin /> },
    ),
    Content: dynamic(() =>
        import('./layers/sign-in/reset-password').then((mod) => mod.ResetPassword),
      { ssr: false, loading: () => <Spin /> },
    ),
  },
};

const SidebarComponent: FC = () => {
  const { signInStep, isSignInVisible, setIsSignInVisible } = useAuthContext();

  const Component = importMapping?.[signInStep] ?? null;

  return (
    <SignUpWrapper>
      <Backdrop isOpen={isSignInVisible} onClick={(): void => setIsSignInVisible(false)} />
      <SidebarContainer isOpen={isSignInVisible && DrawerStep.NONE !== signInStep}>
        {Component?.Header && (
          <Component.Header />
        )}
        <SidebarContent data-e2e-id={'DigandoSidebarContent'}>
          {Component?.Content && (
            <Component.Content />
          )}
        </SidebarContent>
      </SidebarContainer>
    </SignUpWrapper>
  );
};

const SidebarContainer = styled.div<{ isOpen: boolean; }>`
  @media print {
    display: none;
    visibility: hidden;
  }

  position: fixed;
  z-index: 111;
  top: 0;
  right: 0;
  width: 100vw;
  max-width: 600px;
  height: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  background-color: ${theme.white};
  box-shadow: -.2em 0 .2em rgba(0, 0, 0, .3);
  transform: translateX(${(p): string => p.isOpen ? '0%' : '102%'});
  transition: transform 0.3s ease;
  overflow: scroll;
  overflow-x: hidden;
`;

const SidebarContent = styled.div`
  ${spacingTopBottom(4)};
  ${spacingLeftRight(4)};
`;

export const Sidebar = React.memo(SidebarComponent, () => true);
