import { ButtonHTMLAttributes, FC } from 'react';
import styles from './button.module.scss';
import classNames from 'classnames';
import { Loader } from '../loaders/loader';

export interface IDigandoStylingButtonProps {
  spacing?: 'small' | 'default';
  disabled?: boolean;
  loading?: boolean;
  small?: boolean;
  bordered?: boolean;
  fullWidth?: boolean;
}

export interface IDigandoButtonProps extends IDigandoStylingButtonProps {
  type?: ButtonHTMLAttributes<void>['type'];
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onFocus?: () => void;
}

export const Button: FC<IDigandoButtonProps> = (props) => {
  const {
    onClick,
    onFocus,
    type = 'submit',
    spacing = 'default',
    small = false,
    bordered = false,
    disabled = false,
    loading = false,
    fullWidth = false,
  } = props;

  return (
    <button
      className={classNames(styles.button, {
        [styles.bordered]: bordered,
        [styles.small]: small,
        [styles.fullWidth]: fullWidth,
        [styles.spacingSmall]: spacing === 'small',
        [styles.loading]: loading,
      })}
      type={type}
      onClick={onClick}
      onFocus={onFocus}
      disabled={disabled}
    >
      <span className={'button-text'}>{props.children}</span>
      <span className={styles.buttonLoader}>{loading && <Loader invert={false === bordered} />}</span>
    </button>
  );
};
