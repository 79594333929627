import styled from '@emotion/styled';
import { FontPrimaryRegular, theme } from '@digando/react-component-library';
import { mediaQueries } from '../../../styles/media-queries';

export interface IWidgetDescriptionProps {
  isHidden: boolean;
}

export const WidgetDescription = styled.span<IWidgetDescriptionProps>`
  ${FontPrimaryRegular};
  display: inline-block;
  vertical-align: middle;
  font-size: 17px;
  line-height: 22px;
  margin-left: 0.5vw;
  color: inherit;
  overflow: hidden;
  transition: ${theme.textColorHoverTransition};
  max-width: 0;

  @media ${mediaQueries.laptop} {
    max-width: ${(p): string => p.isHidden ? '0' : '400px'};
  }
`;
