'use client';

import { FC, useEffect, useState } from 'react';
import styles from './inputs.module.scss';
import { Icon } from '../icons/icon';
import { themeVariables } from '../styles/theme';
import classNames from 'classnames';
import { Error } from './errorInterface';

export interface IDropdownProps {
  id: string;
  name: string;
  label: string;
  error?: Error & { message: string };
  disabled?: boolean;
  defaultValue?: string;
  isLoading?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  options: {
    value: string;
    name: string;
    key: string;
  }[];
  onChange: (value: string) => void;
}

export const Select: FC<IDropdownProps> = (props) => {
  const {
    name,
    label,
    options,
    defaultValue = '',
    error = null,
    disabled = false,
    autoComplete,
    autoFocus = false,
    id,
    onChange,
  } = props;

  const [value, setValue] = useState<string>(defaultValue);

  useEffect(() => {
    onChange(value);
  }, [value]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div>
      <div
        className={classNames(styles.formElement, {
          [styles.error]: error && !error.isValid,
        })}
        onClick={() => {
          document.getElementById(id)?.focus();
        }}
      >
        <select
          className={classNames(styles.select, {
            [styles.selectedValue]: value !== '',
          })}
          autoFocus={autoFocus}
          id={id}
          name={name}
          autoComplete={autoComplete}
          value={value}
          disabled={disabled}
          onChange={(event): void => {
            const newValue = event.target.value;
            setValue(newValue);
          }}
        >
          {defaultValue === '' && <option key="" value="" disabled></option>}
          {options.map((option) => (
            <option key={option.key} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>

        <span className={styles.selectIcon}>
          <Icon icon={'dropdown'} />
        </span>
      </div>

      {error && !error.isValid && (
        <div key={`${name}-${error.key}`} className={styles.error}>
          <Icon
            icon={'warning'}
            size={'14px'}
            color={themeVariables.errorColor}
          />
          <span>{error.message}</span>
        </div>
      )}
    </div>
  );
};
