import { FC } from 'react';
import styles from './loader.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

type LoaderProps = {
  invert?: boolean;
};

export const Loader: FC<LoaderProps> = ({invert = false}) => {
  return (
    <div className={styles.digandoLoaderBox}>
      <span className={cx({
        digandoLoader: true,
        digandoLoaderInvert: invert,
      })}>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
      </span>
    </div>
  );
};
