'use client';

import { useEffect, useState } from 'react';
import styles from './../inputs.module.scss';
import { Icon } from '../../icons/icon';
import { themeVariables } from '../../styles/theme';
import classNames from 'classnames';
import { Options } from './options';
import { AutocompleteItem } from './option-item';
import { AutoCompleteInputField } from './autocomplete-inputfield';
import { Error } from '../errorInterface';

export type { AutocompleteItem };

export interface IAutocompleteProps<DataType> {
  id: string;
  name: string;
  label: string;
  error?: Error & { message: string };
  value?: string;
  isLoading?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  isLocation?: boolean;
  onSelect: (option: AutocompleteItem<DataType>) => void;
  options: AutocompleteItem<DataType>[];
  getOptions: (value: string) => Promise<void>;
  onClick?: () => void;
  onDelete?: () => void;
  searchThrottle?: number;
  getLocationOfUser?: () => void;
  isLoadingLocation?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange?: (newValue: string) => void;
}

let searchThrottleTimeout: NodeJS.Timeout;

export function AutoComplete<DataType>(props: IAutocompleteProps<DataType>) {
  const {
    name,
    label,
    value = '',
    error = null,
    isLoading = false,
    autoComplete,
    autoFocus = false,
    id,
    options,
    getOptions,
    onSelect,
    onClick,
    onDelete,
    searchThrottle = null,
    isLocation = false,
    getLocationOfUser,
    isLoadingLocation = false,
    onBlur,
    onFocus,
    onChange,
  } = props;

  const [inputValue, setInputValue] = useState<string>(value);
  const [focused, setFocused] = useState<boolean>(false);

  useEffect(() => {
    const search = async () => {
      clearTimeout(searchThrottleTimeout);
      searchThrottleTimeout = setTimeout(async () => {
        await getOptions(inputValue);
      }, searchThrottle ?? 0);
    };

    search();
  }, [inputValue]);

  useEffect(() => {
    if (!focused) {
      onBlur?.();
    } else {
      onFocus?.();
    }
  }, [focused]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div>
      <div
        className={classNames(styles.formElement, {
          [styles.error]: error && !error.isValid,
        })}
      >
        <AutoCompleteInputField
          id={id}
          name={name}
          label={label}
          inputValue={inputValue}
          setInputValue={setInputValue}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          onClick={onClick}
          onDelete={onDelete}
          getOptions={getOptions}
          setFocused={setFocused}
          isLocation={isLocation}
          isLoadingLocation={isLoadingLocation}
          isLoading={isLoading}
          getLocationOfUser={getLocationOfUser}
          onChange={onChange}
        />

        {focused && (
          <Options<DataType> options={options} setValue={setInputValue} onSelect={onSelect} />
        )}
      </div>

      {error && !error.isValid && (
        <div key={`${name}-${error.key}`} className={styles.error}>
          <Icon icon={'warning'} size={'14px'} color={themeVariables.errorColor} />
          <span>{error.message}</span>
        </div>
      )}
    </div>
  );
}
