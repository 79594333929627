import dayjs, { Dayjs } from 'dayjs';
import { atom, DefaultValue, selector, useRecoilValue } from 'recoil';
import { IDateRange } from '../../interfaces/date-range.interface';
import {
  GeoPointInput,
  ProductSearchItemFragment,
  ProductSearchResultFilters,
  ProductSearchSortOption,
  SearchFilterResultFragment,
} from '../../@types/codegen/graphql';
import {
  getDefaultFilterValues,
  getNextWorkingDay,
  IManufacturer,
  IPropertyFilter,
} from '../../hooks/use-product-search-helper';
import { CIRCUMFERENCE_EARTH } from '../../constants/search';
import { generateWeekends } from '../../hooks/use-date-info';
import { ILocation } from '../../interfaces/location.interface';

interface IProductSearchValues {
  searchResults: ProductSearchItemFragment[]; // product search results state for search page
  searchFilter: SearchFilterResultFragment['data'] | null; // search filter state for products searching
  hasMore: boolean; // check if product search results has more product to enable pagination
  isResultsLoading: boolean; // Will be true when a new list of products will be loaded on the search page or anywhere.
  filterValues: IProductSearchFilter; // filter values state for product
}

export interface IProductSearchFilter<T = dayjs.Dayjs> {
  dateRange: IDateRange<T>;
  geoPoint: GeoPointInput | null;
  properties: IPropertyFilter[];
  manufacturers: IManufacturer[];
  tenantKeys: string[];
  isDeliveryPossible?: boolean;
}

export const searchResultsState = atom<ProductSearchItemFragment[]>({
  key: 'searchResults',
  default: [],
});

export const searchFilterState = atom<SearchFilterResultFragment['data'] | null>({
  key: 'searchFilter',
  default: null,
});

export const hasMoreState = atom<boolean>({
  key: 'hasMore',
  default: false,
});

export const isResultsLoadingState = atom<boolean>({
  key: 'isResultsLoading',
  default: false,
});

export const scrollPositionState = atom<string>({
  key: 'scrollPosition',
  default: '',
});

export const activeFiltersState = atom<ProductSearchResultFilters>({
  key: 'activeFilters',
  default: {
    radius: 40075,
    sort: { by: ProductSearchSortOption.PositionAsc },
  },
});

export const manualFiltersState = atom<ProductSearchResultFilters | null>({
  key: 'manualFilters',
  default: null
});

export const specificLocationRadiusState = atom<number | null>({
  key: 'specificLocationRadius',
  default: null
});

export const filterValuesState = atom<IProductSearchFilter>({
  key: 'filterValues',
  default: getDefaultFilterValues(generateWeekends(), null, null, null),
});

export const filterValueLocationSelector = selector<ILocation>({
  key: 'filterValueLocationSelector',
  get: ({ get }) => {
    return get(filterValuesState).geoPoint;
  },
  set: ({ get, set }, location) => {
    if (location instanceof DefaultValue) {
      return;
    }

    let newFilterValues = get(filterValuesState);
    let newLocation = null;

    if (location) {
      newLocation = {
        lat: location?.lat ?? 0,
        lng: location.lng ?? 0,
      };

      newFilterValues = {
        ...newFilterValues,
        geoPoint: newLocation,
      };
    } else {
      newFilterValues = {
        ...newFilterValues,
        geoPoint: null,
      };
    }

    set(filterValuesState, newFilterValues);
  },
});

export const manualRadiusSelector = selector<number | null>({
  key: 'manualRadiusSelector',
  get: ({ get }) => {
    return get(manualFiltersState)?.radius ?? CIRCUMFERENCE_EARTH;
  },
  set: ({ get, set }, radius) => {
    if (radius instanceof DefaultValue) {
      return;
    }

    const activeFilters = get(activeFiltersState);

    set(manualFiltersState, {
      ...activeFilters,
      radius
    });
  },
});

export const filterValueRentalStationSelector = selector<Pick<IProductSearchFilter, 'tenantKeys' | 'geoPoint'>>({
  key: 'filterValueRentalStationSelector',
  get: ({ get }) => {
    const filterValues = get(filterValuesState);

    return {
      geoPoint: filterValues.geoPoint,
      tenantKeys: filterValues.tenantKeys,
    };
  },
  set: ({ get, set }, payload) => {
    if (payload instanceof DefaultValue) {
      return;
    }

    let newFilterValues: IProductSearchFilter = get(filterValuesState);

    if (!payload.tenantKeys || 0 === payload.tenantKeys.length) {
      // eslint-disable-next-line no-console
      console.debug('No tenantKey passed to SET_RENTAL_STATION_FILTERS reducer');

      return;
    }

    if (!payload.geoPoint?.lat || !payload.geoPoint?.lng) {
      // eslint-disable-next-line no-console
      console.debug('Invalid location passed to SET_RENTAL_STATION_FILTERS reducer');

      return;
    }

    newFilterValues = {
      ...newFilterValues,
      geoPoint: payload.geoPoint,
      tenantKeys: payload.tenantKeys,
    };

    set(filterValuesState, newFilterValues);
  },
});

export const filterValuePartialSelector = selector<Partial<IProductSearchFilter<Dayjs>>>({
  key: 'filterValuePartialSelector',
  get: ({ get }) => {
    const filterValues = get(filterValuesState);

    return filterValues;
  },
  set: ({ get, set }, payload) => {
    if (payload instanceof DefaultValue) {
      return;
    }

    const newFilterValues = {
      ...get(filterValuesState),
      ...payload,
    };

    set(filterValuesState, newFilterValues);
  },
});

export const filterValueDateRangeSelector = selector<{
  dateRange: IDateRange;
  nonWorkingDays?: string[];
}>({
  key: 'filterValueDateRangeSelector',
  get: ({ get }) => {
    const filterValues = get(filterValuesState);

    return {
      dateRange: filterValues.dateRange,
      nonWorkingDays: [],
    };
  },
  set: ({ get, set }, payload) => {
    if (payload instanceof DefaultValue) {
      return;
    }

    const filterValues = get(filterValuesState);
    const { nonWorkingDays = [] } = payload;
    let { dateRange } = payload;

    if (false === dateRange.lte.isValid()) {
      dateRange.lte = getNextWorkingDay(dayjs(new Date()), nonWorkingDays, dayjs(new Date()));
    }

    if (false === dateRange.gte.isValid()) {
      dateRange.gte = getNextWorkingDay(dateRange.lte, nonWorkingDays, dateRange.lte);
    }

    // Check if picked date is possible
    dateRange = {
      lte: getNextWorkingDay(dateRange.lte, nonWorkingDays, dateRange.lte),
      gte: getNextWorkingDay(dateRange.gte, nonWorkingDays, dateRange.gte),
    };

    if (dateRange.gte.isSame(filterValues.dateRange.gte) && dateRange.lte.isSame(filterValues.dateRange.lte)) {
      return;
    }

    // Check if end date is before start date
    if (dateRange.lte.isBefore(dateRange.gte)) {
      dateRange = {
        lte: dateRange.gte,
        gte: dateRange.lte,
      };
    }

    if (
      false === filterValues.dateRange.gte.isSame(dateRange.gte)
      || false === filterValues.dateRange.lte.isSame(dateRange.lte)
    ) {
      set(filterValuesState, {
        ...filterValues,
        dateRange: dateRange,
      });
    }
  },
});

export const productSearchAtoms = {
  searchResults: searchResultsState,
  searchFilter: searchFilterState,
  hasMore: hasMoreState,
  isResultsLoading: isResultsLoadingState,
  filterValues: filterValuesState,
  activeFilters: activeFiltersState,
  manualFilters: manualFiltersState,
};

export const useProductSearchValues = (): IProductSearchValues => ({
  searchResults: useRecoilValue(searchResultsState),
  searchFilter: useRecoilValue(searchFilterState),
  hasMore: useRecoilValue(hasMoreState),
  isResultsLoading: useRecoilValue(isResultsLoadingState),
  filterValues: useRecoilValue(filterValuesState),
});
