import { FC } from 'react';
import { IconTypes } from '../../../@types/icons.types';
import { Icon, spacingAround, spacingTop, theme } from '@digando/react-component-library';
import styled from '@emotion/styled';
import { Button } from '../Button/Button';

type PopconfirmProps = {
  text: JSX.Element;
  onConfirm: () => void;
  onCancel: () => void;
  okText: string;
  cancelText: string;
  icon: IconTypes;
  isOpen: boolean;
}

export const Popconfirm: FC<PopconfirmProps> = ({ text, onConfirm, onCancel, okText, cancelText, icon, isOpen }) => {
  return (
    <PopconfirmContainer isOpen={isOpen}>
      <TextContainer>
        <Icon key={'icon'} icon={icon} size={'24px'} color={theme.primaryColor} />
        <Text>{text}</Text>
      </TextContainer>
      <Action>
        <Button onClick={onCancel} small={true} ghost={true}>{cancelText}</Button>
        <Button onClick={onConfirm} small={true} ghost={false}>{okText}</Button>
      </Action>
      <Arrow />
    </PopconfirmContainer>
  );
};

const PopconfirmContainer = styled.div<{ isOpen: boolean; }>`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  width: auto;
  background-color: ${theme.white};
  z-index: 100;
  border-radius: 8px;
  ${spacingAround(2)};

  transition: opacity 0.3s ease-in-out;
  pointer-events: ${p => p.isOpen ? 'auto' : 'none'};
  opacity: ${p => p.isOpen ? 1 : 0};
`;

const TextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  span:first-of-type {
    min-width: 24px;
  }
`;

const Text = styled.span`
  font-size: 17px;
  line-height: 22px;
  color: ${theme.black};
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  ${spacingTop(2)};
`;

const Arrow = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background: #88b7d5;

  &::after {
    bottom: -29px;
    right: 15px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: ${theme.white};
    border-width: 15px;
    margin-left: -14px;
  }

`;
