import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { mediaQueries, theme } from '@digando/react-component-library';
import { Trans, useTranslation } from 'next-i18next';
import { useMutation } from '@apollo/client';
import {
  MutationSubscribeArgs,
  SubscribeNewsletterDocument,
  SubscribeNewsletterMutation,
} from '../../@types/codegen/graphql';
import Bugsnag from '@bugsnag/js';
import { newsletterSubscriptionTracking } from '../../lib/google/tracking-events';
import Link, { LinkProps } from 'next/link';
import { Popconfirm } from '../elements/Popconfirm/Popconfirm';
import { Input, Button } from '@digando/common-ui';
import { useValidator } from '../../hooks/use-validator';

interface INewsletterComponentProps {
  identifier: string;
}

const NewsletterComponent: FC<INewsletterComponentProps> = ({ identifier }) => {
  const [email, setEmail] = useState<string>('');
  const [t] = useTranslation(['form', 'common']);

  const inputError = useValidator(email, 'email');
  const [hasSubmitted, setGotSubmitted] = useState<boolean>(false);

  const [subscribe] = useMutation<
    SubscribeNewsletterMutation,
    MutationSubscribeArgs
  >(SubscribeNewsletterDocument);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const fieldId = `email-newsletter-${identifier}`;

  const doSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setGotSubmitted(true);

    if (inputError.isValid) {
      try {
        setVisible(true);
      } catch (error) {
        setVisible(false);
      }
      return;
    }
  };

  const handleConfirm = async (): Promise<void> => {
    setVisible(false);

    newsletterSubscriptionTracking(email);

    setConfirmLoading(true);
    const variables = {
      input: {
        email: email,
        firstName: '',
        lastName: '',
      },
    };

    try {
      await subscribe({
        variables,
      });

      setSuccess(true);
    } catch (error: unknown) {
      Bugsnag.notify(JSON.stringify(error));
    }

    setConfirmLoading(false);
  };

  const LinkText = (
    props: React.PropsWithChildren<LinkProps>
  ): React.ReactElement => {
    return (
      <Link {...props} href={props.href || ''} target="_blank" rel="noreferrer">
        {props.children}
      </Link>
    );
  };

  return (
    <FormContainer>
      <Popconfirm
        text={
          <Trans
            i18nKey={'form:newsletter.subscription-confirmation'}
            components={{
              linktext: <LinkText href="/datenschutz" />,
            }}
          />
        }
        isOpen={visible}
        onConfirm={handleConfirm}
        onCancel={(): void => {
          setVisible(false);
        }}
        okText={t('common:yes')}
        cancelText={t('common:no')}
        icon={'warning'}
      />

      <form
        onSubmit={(e) => {
          doSubmit(e);
        }}
      >
        <NewsletterWrapper>
          {false === success && (
            <>
              <FormItemWrapper>
                <Input
                  label={t('form:email-address')}
                  id={fieldId}
                  name={'email'}
                  autoFocus={false}
                  autoComplete={'email'}
                  value={email}
                  onChange={(value) => {
                    setEmail(value);
                  }}
                  error={hasSubmitted && inputError ? {
                    ...inputError,
                    message: t(`form:error.${inputError.errorCode ?? 'no-error'}`),
                  } : undefined}
                />
              </FormItemWrapper>

              <ButtonWrapper>
                <Button
                  type="submit"
                  spacing={'small'}
                  loading={confirmLoading}
                >
                  {t('form:newsletter.subscribe-button-text')}
                </Button>
              </ButtonWrapper>
            </>
          )}

          {true === success && (
            <SuccessMessage>
              {t('form:newsletter.subscription-success')}
            </SuccessMessage>
          )}
        </NewsletterWrapper>
      </form>
    </FormContainer>
  );
};

const NewsletterWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 16px;
  align-items: top;
`;

const SuccessMessage = styled.div`
  grid-column: auto / span 2;
  color: ${theme.white};
`;

const FormItemWrapper = styled.div`
  grid-column: auto / span 2;

  @media ${mediaQueries.tablet} {
    grid-column: auto / span 1;
  }
`;

const ButtonWrapper = styled.div`
  grid-column: auto / span 2;

  .button-text {
    text-transform: uppercase;
  }

  @media ${mediaQueries.tablet} {
    grid-column: auto / span 1;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  position: relative;
  width: 100%;
`;

export const Newsletter = React.memo(NewsletterComponent, () => {
  return true;
});
