import React, { createContext, Dispatch, FC, SetStateAction, useContext, useState } from 'react';
import {
  Company,
  CompanyAddress,
  Gender,
  RegisterDocument,
  RegisterInput,
  RegisterMutation,
  RegisterMutationVariables,
} from '../@types/codegen/graphql';
import { useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import Bugsnag from '@bugsnag/js';
import { IError } from '../lib/get-status-code';
import nookies from 'nookies';
import { DigandoCountryCode } from '../constants/cookies';
import { SignUpStep } from '../components/sidebar/interfaces/drawer.interfaces';
import { signUpTracking } from '../lib/google/tracking-events';

export interface ISignUpContextProps {
  inProgress: boolean;
  company: Company | null;
  registerErrors: string[];
  setRegisterErrors: React.Dispatch<React.SetStateAction<string[]>>;
  countryCode: CompanyAddress['country'] | null;
  userData: Partial<Exclude<RegisterInput, 'organization'> | null>;
  setCountryCode: (country: ISignUpContextProps['countryCode']) => void;
  setCompany: (companyAddress: Company | null) => void;
  setUserData: (userData: Exclude<RegisterInput, 'organization'> | null) => void;
  signUpStep: SignUpStep;
  doRegister: () => Promise<boolean>;
  setSignUpStep: Dispatch<SetStateAction<SignUpStep>>;

}

const SignUpContext = createContext<ISignUpContextProps>({
  inProgress: false,
  company: null,
  userData: null,
  countryCode: null,
  registerErrors: [],
  signUpStep: SignUpStep.SIGN_UP_COMPANY,
  setRegisterErrors: (): void => {
    // eslint-disable-next-line no-console
    console.error('SignUpContext not ready yet.');
  },
  setCompany: (): void => {
    // eslint-disable-next-line no-console
    console.error('SignUpContext not ready yet.');
  },
  setCountryCode: (): void => {
    // eslint-disable-next-line no-console
    console.error('SignUpContext not ready yet.');
  },
  setUserData: (): void => {
    // eslint-disable-next-line no-console
    console.error('SignUpContext not ready yet.');
  },
  doRegister: async (): Promise<boolean> => {
    // eslint-disable-next-line no-console
    console.error('SignUpContext not ready yet.');

    return false;
  },
  // eslint-disable-next-line no-console
  setSignUpStep: () => console.error('SignUpContext not ready yet.'),
});

const catchErrors = (errors: GraphQLError[]): string[] => {
  return errors.map(error => {
    return error.extensions.code as string ?? error.message ?? '';
  });
};

export const SignUpWrapper: FC<{  children: React.ReactNode; }> = ({ children }) => {
  const cookies = nookies.get();

  const cookieCountryCode = cookies[DigandoCountryCode] ?? null;

  const [signUpStep, setSignUpStep] = useState<SignUpStep>(SignUpStep.SIGN_UP_COMPANY);
  const [countryCode, setCountryCode] = useState<ISignUpContextProps['countryCode']>(cookieCountryCode);
  const [company, setCompany] = useState<ISignUpContextProps['company']>(null);
  const [userData, setUserData] = useState<ISignUpContextProps['userData']>({
    gender: Gender.Male,
  });
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [registerErrors, setRegisterErrors] = useState<string[]>([]);
  const [register] = useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument);

  const doRegister = async (): Promise<boolean> => {
    setInProgress(true);

    try {
      const res = await register({
        variables: {
          input: {
            gender: userData?.gender ?? Gender.Male,
            firstName: userData?.firstName ?? '',
            lastName: userData?.lastName ?? '',
            phone: userData?.phone ?? '',
            email: userData?.email ?? '',
            password: userData?.password ?? '',
            organization: {
              name: company?.companyName ?? '',
              vatId: company?.vatId ?? '',
              address: {
                street: company?.address?.street ?? '',
                streetNumber: company?.address?.streetNumber ?? '',
                zip: company?.address?.zip ?? '',
                city: company?.address?.city ?? '',
                country: company?.address?.country ?? '',
              },
            },
            affiliateId: userData?.affiliateId,
          },
        },
      });

      setInProgress(false);
      setRegisterErrors([]);

      signUpTracking(userData?.email ?? 'unknown', userData?.affiliateId ?? '');

      return (!!res.data?.PAPI_register.user?.id) ?? false;
    } catch (error: unknown) {
      const appoloError = JSON.parse(JSON.stringify(error));

      if(appoloError.graphQLErrors[0] && 'vatId' === appoloError.graphQLErrors[0].extensions.message){
        setSignUpStep(SignUpStep.SIGN_UP_COMPANY);
        setRegisterErrors([...registerErrors, 'vatId']);

        return false;
      }

      Bugsnag.notify(JSON.stringify(error));
      setInProgress(false);

      setRegisterErrors(catchErrors((error as IError)?.graphQLErrors ?? []));

      return false;
    }
  };

  return (
    <>
      <SignUpContext.Provider value={{
        inProgress,
        company,
        countryCode,
        userData,
        registerErrors,
        signUpStep,
        setRegisterErrors,
        setCompany,
        setCountryCode,
        setUserData,
        doRegister,
        setSignUpStep,
      }}>
        {children}
      </SignUpContext.Provider>
    </>
  );
};

export function useSignUpContext(): ISignUpContextProps {
  return useContext(SignUpContext);
}
