import React, { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import AUTH_MUTATION from '../graphql/mutations/auth.graphql';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import { getApolloClient } from '../lib/apollo-client/apollo-client';
import { useMe } from '../@types/codegen/page';
import {
  AuthenticateMutation,
  MeDetailsFragment,
  MutationAuthenticateArgs,
} from '../@types/codegen/graphql';
import { destroyCookie, setCookie } from 'nookies';
import Bugsnag from '@bugsnag/js';
import { DigandoAccessToken } from '../constants/cookies';
import { useRouter } from 'next/router';
import { DrawerStep } from '../components/sidebar/interfaces/drawer.interfaces';
import { IError } from '../lib/get-status-code';
import { PostLoginActionEnum } from '../interfaces/post-login-action.interface';

interface IAuthContextProps {
  me: MeDetailsFragment | null;
  login: (values: IAuthData) => Promise<boolean>;
  logout: () => void;
  refetch: () => void;
  isSignInVisible: boolean;
  postLoginAction: PostLoginActionEnum;
  loginState: LoginStates;
  signInStep: DrawerStep;
  setIsSignInVisible: Dispatch<SetStateAction<boolean>>;
  setPostLoginAction: Dispatch<SetStateAction<PostLoginActionEnum>>;
  setSignInStep: Dispatch<SetStateAction<DrawerStep>>;
  loading: boolean;
  redirectLink: string | null;
  setRedirectLink: Dispatch<SetStateAction<string | null>>;
  setHasLoginError: Dispatch<SetStateAction<false | string>>;
  hasLoginError: false | string;
}

export interface IAuthData {
  email: string;
  password: string;
}

export enum LoginStates {
  LOGGED_IN,
  LOGGED_OUT,
}

const AuthContext = createContext<IAuthContextProps>({
  me: null,
  loading: false,
  isSignInVisible: false,
  postLoginAction: PostLoginActionEnum.defaultValue,
  signInStep: DrawerStep.SIGN_IN,
  redirectLink: null,
  loginState: LoginStates.LOGGED_OUT,
  hasLoginError: false,
  login: async () => {
    // eslint-disable-next-line no-console
    console.error('Auth Provider not initialized.');

    return false;
  },
  // eslint-disable-next-line no-console
  logout: () => console.error('Auth Provider not initialized.'),
  // eslint-disable-next-line no-console
  refetch: () => console.error('Auth Provider not initialized.'),
  // eslint-disable-next-line no-console
  setIsSignInVisible: () => console.error('Auth Provider not initialized.'),
  // eslint-disable-next-line no-console
  setPostLoginAction: () => console.error('Auth Provider not initialized.'),
  // eslint-disable-next-line no-console
  setSignInStep: () => console.error('Auth Provider not initialized.'),
  // eslint-disable-next-line no-console
  setRedirectLink: () => console.error('Auth Provider not initialized.'),
  // eslint-disable-next-line no-console
  setHasLoginError: (hasError: SetStateAction<false | string>) => console.error('Auth Provider not initialized.'),
});

interface AuthWrapperProps {
  children: React.ReactNode;
  me?: MeDetailsFragment | null;
}

export const AuthWrapper: FC<AuthWrapperProps> = ({children}) => {
  const [isSignInVisible, setIsSignInVisible] = useState<boolean>(false);
  const [signInStep, setSignInStep] = useState<DrawerStep>(DrawerStep.NONE);
  const [authenticate] = useMutation<AuthenticateMutation, MutationAuthenticateArgs>(AUTH_MUTATION);
  const [postLoginAction, setPostLoginAction] = useState<PostLoginActionEnum>(PostLoginActionEnum.defaultValue);
  const { data, refetch, error, loading } =  useMe(() => {
    return {
      fetchPolicy: 'network-only'
    };
  });

  const [me, setMe] = useState<MeDetailsFragment | null>(data?.PAPI_me ?? null);
  const initialLoginState = (data?.PAPI_me.states.loggedIn ?? false) ? LoginStates.LOGGED_IN : LoginStates.LOGGED_OUT;
  const [loginState, setLoginState] = useState<LoginStates>(initialLoginState);
  const [redirectLink, setRedirectLink] = useState<IAuthContextProps['redirectLink']>(null);
  const [hasLoginError, setHasLoginError] = useState<false | string>(false);

  const router = useRouter();

  useEffect(() => {
    if ('email-verified' === router?.query?.uid) {
      setSignInStep(DrawerStep.SIGN_UP);
      setIsSignInVisible(true);
    }
  }, []);

  const login = async (values: IAuthData): Promise<boolean> => {
    try {
      const res = await authenticate({
        variables: {
          input: values,
        },
      });

      const { data: authData, errors } = res;

      setCookie(null, DigandoAccessToken, authData?.authenticate.token ?? '', {
        path: '/',
        sameSite: 'strict',
      });
      refetch();

      await persistCache({
        cache: getApolloClient().cache,
        storage: new LocalStorageWrapper(window.localStorage),
      });

      if (null !== redirectLink) {
        await router.push(redirectLink);
      } else {
        await router.push(router.asPath);
      }

      return 0 === (errors?.length ?? 0);
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.error(error);

      const authErrors: {[index: string]: string} = {
        'Email is not verified': 'email-not-verified',
        'User is disabled': 'user-is-disabled',
        'Email is invalid': 'email-is-invalid',
        'Username and/or password are wrong': 'wrong-username-or-password'
      };
      const errorMessage = (error as IError).graphQLErrors?.[0].message;

      setHasLoginError(undefined !== errorMessage && (authErrors?.[errorMessage] ?? 'wrong-username-or-password'));

      return false;
    }
  };

  const logout = async (): Promise<void> => {
    destroyCookie(null, DigandoAccessToken, {
      path: '/',
    });

    setMe(null);

    await persistCache({
      cache: getApolloClient().cache,
      storage: new LocalStorageWrapper(window.localStorage),
    });

    refetch();
  };

  useEffect(() => {
    if (router.query?.token) {
      setSignInStep(DrawerStep.RESET_PASSWORD);
      setIsSignInVisible(true);
    }
  }, [router.query?.token]);

  useEffect(() => {
    const newUserData = data?.PAPI_me ?? null;

    if (newUserData !== me) {
      setMe(newUserData);
      setLoginState((newUserData?.states.loggedIn ?? false) ? LoginStates.LOGGED_IN : LoginStates.LOGGED_OUT);

      Bugsnag.setUser(newUserData?.user?.id, newUserData?.user?.email);
    }
  }, [data, setMe, loading]);

  useEffect(() => {
    if (error) {
      setMe(null);
    }
  }, [error, setMe]);

  return (
    <AuthContext.Provider value={{
      me,
      refetch,
      login,
      logout,
      loading,
      loginState,
      redirectLink,
      setRedirectLink,
      isSignInVisible,
      setIsSignInVisible,
      postLoginAction,
      setPostLoginAction,
      signInStep,
      setSignInStep,
      hasLoginError,
      setHasLoginError,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthContext(): IAuthContextProps {
  return useContext(AuthContext);
}
