import React, { FC } from 'react';
import styled from '@emotion/styled';
import { WidgetDescription } from './../styles/widget-description';
import { WidgetContainer } from './../styles/widget-container';
import { useTranslation } from 'next-i18next';
import { useNavigationContext } from '../../../store/navigation.context';
import { theme } from '@digando/react-component-library';

const MenuToggler: FC = () => {
  const { isOpen, setIsOpen } = useNavigationContext();
  const [t] = useTranslation('common');

  return (
    <Container onClick={(): void => setIsOpen(!isOpen)} noLeftSpacing={true}  data-e2e-id={'mainMenuToggler'}>
      <BurgerIcon isClosed={!isOpen}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </BurgerIcon>
      <Description isHidden={false}>{t('menu')}</Description>
    </Container>
  );
};

const BurgerIcon = styled.div<{ isClosed: boolean }>`
  width: 30px;

  div {
    background-color: ${theme.isInverted ? theme.white : theme.black};
    width: ${(p): string => p.isClosed ? '20px' : '30px'};
    height: 3px;
    border-radius: 1px;
    margin-bottom: 2.5px;
    transition: all 0.3s ease, opacity 0.1s ease;
    margin-left: ${(p): string => p.isClosed ? '7.5px' : '0'};

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-of-type(1),
    &:nth-of-type(4) {
      opacity: ${(p): string => p.isClosed ? '1' : '0'};
    }

    &:nth-of-type(2) {
      transform:
        rotate(${(p): string => p.isClosed ? '0' : '45deg'})
        translateX(${(p): string => p.isClosed ? '0' : '3.5px'});
    }

    &:nth-of-type(3) {
      transform:
        rotate(${(p): string => p.isClosed ? '0' : '-45deg'})
        translateX(${(p): string => p.isClosed ? '0' : '3.5px'});
    }
  }
`;

const Container = styled(WidgetContainer)`
  cursor: pointer;
`;

const Description = styled(WidgetDescription)`
  color: ${theme.isInverted ? theme.white : theme.black};
`;

export default MenuToggler;
