/* eslint-disable */
import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Icon, theme } from '@digando/react-component-library';
import { useAuthContext } from '../../store/auth.context';
import { useCartContext } from '../../store/cart.context';
import dayjs from 'dayjs';
import { useProductSearchValues } from '../../store/recoil/product-search';

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');

  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error(err);
  }

  document.body.removeChild(textArea);
}

function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);

    return;
  }

  navigator.clipboard.writeText(text)
    .then(
      () => {},
      (err) => console.error(err),
    );
}

const CopyDetails: FC = () => {
  const { me } = useAuthContext();
  const { cartId } = useCartContext();
  const { filterValues } = useProductSearchValues();

  return (
    <CopyDetailsContainer title={'Copy information for developer'} onClick={() => {
      const text = `
        *URL:* ${location.href}
        *User:* ${me?.user?.id ?? 'Logged out'}
        *Cart ID:* ${cartId}
        *Date:* ${dayjs().format('YYYY.MM.DD mm:hh')}
        *Agent*: ${navigator.userAgent}

        *FilterValues:*
        {code:json}
          ${JSON.stringify(filterValues)}
        {code}
      `;

      copyTextToClipboard(text);
    }}>
      <Icon size={'17px'} icon={'copy'}/>
    </CopyDetailsContainer>
  );
};

export default CopyDetails;

const CopyDetailsContainer = styled.button`
  position: fixed;
  z-index: 110;
  bottom: 10px;
  right: 10px;
  width: 35px;
  height: 35px;
  background-color: ${theme.primaryColor};
  color: ${theme.white};
  font-size: 25px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  border: none;
`;
