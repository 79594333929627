import styles from "./variables.module.scss";

export const themeVariables = {
  primaryColor: styles.primaryColor,
  primaryHoverColor: styles.primaryHoverColor,
  primaryActiveColor: styles.primaryActiveColor,
  errorColor: styles.errorColor,
  petrol: styles.petrol,
  black: styles.black,
  grey: styles.grey,
  greyTypo: styles.greyTypo,
  lightGrey: styles.lightGrey,
  white: styles.white,
  green: styles.green,
  backgroundLight: styles.backgroundLight,
  backgroundGrey: styles.backgroundGrey,
  redLight: styles.redLight,

  textColorHoverTransition: styles.textColorHoverTransition,
};
