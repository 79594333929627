import { FC, useState } from 'react';
import styles from './inputs.module.scss';
import { Icon } from '../icons/icon';
import { themeVariables } from '../styles/theme';
import classNames from 'classnames';
import { Loader } from '../loaders/loader';
import { Error } from './errorInterface';

export interface IInputProps {
  id: string;
  name: string;
  label: string;
  error?: Error & { message: string };
  defaultValue?: string;
  isLoading?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  onDelete?: () => void;
  value?: string;
  type?: string;
}

export const Input: FC<IInputProps> = (props) => {
  const {
    name,
    label,
    defaultValue = '',
    error = null,
    isLoading = false,
    autoComplete,
    autoFocus = false,
    id,
    onChange,
    onFocus,
    onBlur,
    onClick,
    onDelete,
    value,
    type = 'text',
  } = props;

  const [internalValue, setInternalValue] = useState<string>(defaultValue);

  return (
    <div>
      <div
        className={classNames(styles.formElement, {
          [styles.error]: error && !error.isValid,
        })}
      >
        {/* To enable pseudo css that we can use the :placeholde-shown selector, we need to add a placeholder (empty string is enough) */}
        <input
          className={styles.input}
          type={type}
          autoFocus={autoFocus}
          id={id}
          name={name}
          autoComplete={autoComplete}
          value={value || internalValue}
          placeholder={' '}
          onChange={(event): void => {
            const newValue = event.target.value;

            onChange(newValue);

            if (value !== undefined) {
              return;
            }

            setInternalValue(newValue);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
        />
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
        {value != '' ||
          (internalValue != '' && (
            <span
              className={styles.deleteIcon}
              onClick={() => {
                setInternalValue('');
                if (onDelete) {
                  onDelete();
                }
              }}
            >
              {<Icon icon={'close'} color={themeVariables.black} />}
            </span>
          ))}

        {isLoading && (
          <div className={styles.loader}>
            <Loader />
          </div>
        )}
      </div>

      {error && !error.isValid && (
        <div key={`${name}-${error.key}`} className={styles.error}>
          <Icon icon={'warning'} size={'14px'} color={themeVariables.errorColor} />
          <span>{error.message}</span>
        </div>
      )}
    </div>
  );
};
