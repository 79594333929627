import React, { ButtonHTMLAttributes, FC } from 'react';
import { Spin } from 'antd';
import { ButtonWrapper } from './Button.style';

export interface IDigandoStylingButtonProps {
  spacing?: 'small' | 'default'; // @todo: add extendedSpacing
  disabled?: boolean;
  loading?: boolean;
  ghost?: boolean;
  small?: boolean;
  bordered?: boolean;
  fullWidth?: boolean;
  inverted?: boolean;
}

interface IDigandoButtonProps extends IDigandoStylingButtonProps {
  type?: ButtonHTMLAttributes<void>['type'];
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onFocus?: () => void;
}

export const Button: FC<IDigandoButtonProps> = (props) => {
  const {
    onClick,
    onFocus,
    type = 'submit',
    spacing = 'default',
    ghost = false,
    small = false,
    bordered = false,
    disabled = false,
    loading = false,
    fullWidth = false,
    inverted = false,
  } = props;

  return (
    <ButtonWrapper
      spacing={spacing}
      inverted={inverted}
      className={`button-container ${ghost ? 'ghost ' : ''}${small ? ' small ' : ''}${bordered ? ' bordered ' : ''} ${loading ? ' loading' : ''} ${fullWidth ? ' full-width' : ''}`}
    >
      <button
        className={'button'}
        type={type}
        onClick={onClick}
        onFocus={onFocus}
        disabled={disabled}
      >
        <span className={'button-text'}>
          {props.children}
        </span>
        <span className={'button-loader'}>
          <Spin size={'default'} />
        </span>
      </button>
    </ButtonWrapper>
  );
};

