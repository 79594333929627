import styles from './../inputs.module.scss';
import { Icon } from '../../icons/icon';
import { themeVariables } from '../../styles/theme';
import { Loader } from '../../loaders/loader';

interface IAutocompleteInputFieldProps {
  id: string;
  name: string;
  label: string;
  inputValue: string;
  setInputValue: (value: string) => void;
  autoComplete?: string;
  autoFocus: boolean;
  isLocation: boolean;
  getLocationOfUser?: () => void;
  getOptions: (value: string) => Promise<void>;
  onClick?: () => void;
  onDelete?: () => void;
  setFocused: (focused: boolean) => void;
  isLoadingLocation: boolean;
  isLoading?: boolean;
  onBlur?: () => void;
  onChange?: (newValue: string) => void;
}

export function AutoCompleteInputField(props: IAutocompleteInputFieldProps) {
  const {
    name,
    inputValue,
    setInputValue,
    id,
    autoFocus,
    autoComplete,
    onClick,
    onDelete,
    getOptions,
    setFocused,
    label,
    isLocation,
    getLocationOfUser,
    isLoadingLocation,
    isLoading,
    onBlur,
  } = props;

  return (
    <div>
      <input
        className={styles.input}
        type="text"
        autoFocus={autoFocus}
        id={id}
        name={name}
        autoComplete={autoComplete}
        value={inputValue}
        placeholder={' '}
        onChange={(event): void => {
          const newValue = event.target.value;
          setInputValue(newValue);
        }}
        onFocus={async (): Promise<void> => {
          setFocused(true);
          await getOptions(inputValue);
        }}
        onBlur={(): void => {
          setTimeout(() => {
            setFocused(false);
          }, 200);

          if (onBlur) {
            onBlur();
          }
        }}
        onClick={onClick}
      />
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
      {inputValue !== '' && (
        <span
          className={styles.deleteIcon}
          onClick={() => {
            setInputValue('');
            if (onDelete) {
              onDelete();
            }
          }}
        >
          {<Icon icon={'close'} color={themeVariables.black} />}
        </span>
      )}

      {isLoading ||
        (isLoadingLocation && (
          <span className={styles.loaderIcon}>
            <span className={styles.loader}>
              <Loader />
            </span>
          </span>
        ))}

      {inputValue === '' && isLocation && !isLoadingLocation && (
        <span
          className={styles.locationIcon}
          onClick={() => {
            if (getLocationOfUser) {
              getLocationOfUser();
            }
          }}
        >
          {<Icon icon={'location'} color={themeVariables.primaryColor} />}
        </span>
      )}
    </div>
  );
}
