import React, { FC } from 'react';

import { FontPrimaryRegular, FontPrimarySemiBold, Icon, theme } from '@digando/react-component-library';
import styled from '@emotion/styled';

import { WidgetDescription } from '../styles/widget-description';
import { WidgetContainer } from '../styles/widget-container';
import { mediaQueries } from '../../../styles/media-queries';
import { useTranslation } from 'next-i18next';

interface ISupportProps {
  color?: string;
  iconSize?: string;
  workHours?: JSX.Element | string;
  fontWeightBold?: boolean;
}

export const Support: FC<ISupportProps> = ({ ...props }) => {
  const [t] = useTranslation('common');

  return (
    <SupportWidgetContainer>
      <Icon icon={'phone'} size={props.iconSize ?? '30px'} color={props.color ?? (theme.isInverted ? theme.white : theme.black)}/>
      <SupportText isHidden={false} fontWeightBold={props.fontWeightBold ?? false} color={props.color ?? (theme.isInverted ? theme.white : theme.black)}>
        {t('support-hotline')}&nbsp;<a href={'tel:' + t('support-phone')}>{t('support-phone')}</a>
        <WorkHoursWrapper>{props.workHours}</WorkHoursWrapper>
      </SupportText>
    </SupportWidgetContainer>
  );
};

const SupportWidgetContainer = styled(WidgetContainer)`
  display: none;

  @media ${mediaQueries.tablet} {
    display: flex;
  }

  @media print {
    a[href]:after {
      content: none !important;
    }
  }
`;

const WorkHoursWrapper = styled.div`
  ${FontPrimaryRegular};
`;

const SupportText = styled(WidgetDescription)<{ fontWeightBold: boolean; color: string; }>`
  max-width: none;
  font-weight: ${(p): string => (p.fontWeightBold ? FontPrimarySemiBold : FontPrimaryRegular)};
  color: ${(p): string => (p.color ? p.color : theme.black)};

  a {
    color: ${(p): string => (p.color ? p.color : 'inherit')};
    font: inherit;
    text-decoration: none;
  }
`;
