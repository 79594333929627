import styled from '@emotion/styled';
import { mediaQueriesSizes, spacingLeftRight, spacingTopBottom, theme } from '@digando/react-component-library';

export const DigandoOverlayWrapper = styled.div<{isOpen: boolean}>`
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  position: fixed;
  inset: 0;
  background-color: ${theme.backgroundLight};
  z-index: 200;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  ${spacingLeftRight(4)};

  @media all and ${mediaQueriesSizes.laptop} {
    display: none !important;
  }

  @media print {
    display: none !important;
  }
`;

export const DigandoOverlayComponentLoader = styled.div`
  text-align: center;
  ${spacingTopBottom(6)};
`;
