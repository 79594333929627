import * as React from 'react';
// import { theme } from '@digando/react-component-library';
import getConfig from 'next/config';
import NextImage from 'next/image';

const { publicRuntimeConfig } = getConfig();
const themeName = publicRuntimeConfig.theme ?? 'default';
const LogoSVG = await import((`../styles/${themeName}/logo-short.svg`));
const LogoInvertedSVG = await import((`../styles/${themeName}/logo-short-inverted.svg`));

function SvgPlatformLogoShort(props: React.SVGProps<SVGSVGElement> & { isInverted?: boolean }) {
  const useInvertedLogo = props.isInverted ?? false;

  return (
    <NextImage
      src={useInvertedLogo ? LogoInvertedSVG.default.src : LogoSVG.default.src}
      priority={true}
      unoptimized={true}
      alt={"Logo"}
      title={"Logo"}
      width={LogoSVG.default.width}
      height={LogoSVG.default.height}
    />
  );
}

export default SvgPlatformLogoShort;
