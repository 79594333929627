import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import {
  spacingBottom, spacingCalculator, spacingLeft,
  spacingRight,
  spacingTop,
  spacingTopBottom,
  theme
} from '@digando/react-component-library';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { ContentContainerPageHeader } from '../../layout/container';
import MenuToggler from './components/menu-toggler';
import { Support } from './components/support';
import { Basket } from './components/basket';
import { WidgetContainer } from './styles/widget-container';
import { SupportMobile } from './components/support-mobile';
import { mediaQueries } from '../../styles/media-queries';
import { useAvailabilityContext } from '../../store/availability.context';
import { useNavigationContext } from '../../store/navigation.context';
import { readableUTCTime } from '../../helper/readable-time';
import { PageLoader } from './components/page-loader';
import { Chat } from './components/chat';
import { AvailabilityCheck } from '../AvailabilityCheck/AvailabilityCheck';
import SvgPlatformLogo from '../../svg/PlatformLogo';
import SvgPlatformLogoShort from '../../svg/PlatformLogoShort';
import dynamic from 'next/dynamic';
import { useGlobalSearchValues } from '../../store/recoil/global-search';
import { ProfileItem } from './components/profile-item';
import { isFeatureFlagEnabled } from '../../lib/environment';

const GlobalSearchInsideHeaderDynamic: React.ComponentType = dynamic(() =>
  import('../global-search/components/global-search-bar-inside-header-component').then((module) => module.GlobalSearchBarInHeader), { ssr: false });

// @todo: Load only if the user wants to open the navigation, otherwise prevent loading of the navigation.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Navigation = dynamic(() => import('./components/navigation/navigation').then((mod) => mod.Navigation), { ssr: false });
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const NavigationMobile = dynamic(() => import('./components/navigation/navigation-mobile').then((mod) => mod.NavigationMobile), { ssr: false });

interface ISearchInHeaderProps {
  mainSearchVisible: boolean;
}

interface ICenterItemContainerDesktop {
  delayedComponent: boolean;
}

let delayedComponentTimeout: NodeJS.Timeout;

const PageHeader: FC = () => {
  const { isOpen } = useNavigationContext();
  const [isInUse, setIsInUse] = useState<boolean>(false);
  const [t] = useTranslation(['common', 'search']);
  const { loadedDateTime, reload, isAvailabilityCheckVisible } = useAvailabilityContext();
  const { mainSearchVisible } = useGlobalSearchValues();
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [delayedComponent, setDelayedComponent] = useState(true);


  const firstRender = useMemo(() =>
      setIsFirstRender(true),
    [],
  );

  useEffect(() => {
    !mainSearchVisible && setIsFirstRender(false);
    firstRender;
  }, [mainSearchVisible]);

  useEffect(() => {
    clearTimeout(delayedComponentTimeout);

    delayedComponentTimeout = setTimeout(() => setDelayedComponent(mainSearchVisible), mainSearchVisible ? 300 : 0);
  }, [mainSearchVisible]);

  useEffect(() => {
    if (true === isOpen && false === isInUse) {
      setIsInUse(true);
    }
  }, [isOpen]);

  const availabilityCheck = (): ReactElement => {
    return (
      <AvailabilityCheckContainer>
        <AvailabilityCheck
          lastUpdateLabel={t('search:last-update')}
          time={loadedDateTime ? readableUTCTime(loadedDateTime) : ''}
          onRequestUpdate={reload}
          oClockLabel={t('search:clock')}
          timeLabel={t('search:guaranteed-availability')}
          availabilityLabel={t('search:check-availability')}
          loadedDateTime={loadedDateTime}
        />
      </AvailabilityCheckContainer>
    );
  };

  return <>
    <PageHeaderContainer>
      <PageHeaderInner>
        <PageLoader />
        <TopBar>
          {isFeatureFlagEnabled('isSupportEnabled') && (
            <SupportMobile />
          )}
          {isFeatureFlagEnabled('isChatEnabled') && (
            <Chat mobile={true} />
          )}
        </TopBar>

        <PageHeaderContent>
          <LeftContainer>
            <PageMenuLeft>
              <MenuToggler />
            </PageMenuLeft>

            <DigandoLogoWrapperMobile mainSearchVisible={mainSearchVisible}>
              <Link href={'/'} passHref prefetch={false} aria-label='Digando.com'>
                {delayedComponent ? <SvgPlatformLogo isInverted={theme.isInverted} /> : <SvgPlatformLogoShort isInverted={theme.isInverted}  />}
              </Link>
            </DigandoLogoWrapperMobile>

            <DigandoLogoWrapperDesktop>
              <Link href={'/'} passHref prefetch={false} aria-label='Digando.com'>
                <>
                  <LaptopWrapper>
                    {delayedComponent
                      ? <SvgPlatformLogo isInverted={theme.isInverted} />
                      : <LogoShortWrapper mainSearchVisible={delayedComponent}><SvgPlatformLogoShort isInverted={theme.isInverted} /></LogoShortWrapper>}
                  </LaptopWrapper>
                  <DesktopWrapper><SvgPlatformLogo isInverted={theme.isInverted} /></DesktopWrapper>
                </>
              </Link>
            </DigandoLogoWrapperDesktop>
            {delayedComponent && <CenterItem>
              {isFeatureFlagEnabled('isSupportEnabled') && (
                <Support />
              )}

              {isFeatureFlagEnabled('isChatEnabled') && (
                <Chat mobile={false} />
              )}
            </CenterItem>}
          </LeftContainer>

          <CenterItemContainerDesktop delayedComponent={delayedComponent}>
            {!isFirstRender && (
              <GlobalSearchWrapper mainSearchVisible={mainSearchVisible}>
                <GlobalSearchInsideHeaderDynamic />
              </GlobalSearchWrapper>
            )}
          </CenterItemContainerDesktop>

          <RightContainer>
            {null !== loadedDateTime && delayedComponent && isAvailabilityCheckVisible && (
              availabilityCheck()
            )}
            <ProfileItem small={!delayedComponent} />

            <Basket isHidden={false} />

            <PageMenuRight>
              <MenuToggler />
            </PageMenuRight>
          </RightContainer>

        </PageHeaderContent>
      </PageHeaderInner>
    </PageHeaderContainer>

    {true === isInUse && (
      <>
        <Navigation />
        <NavigationMobile />
      </>
    )}
  </>;
};

const LeftContainer = styled.div`
  display: flex;
  align-items: center;

  @media ${mediaQueries.laptop} {
    flex-grow: 1;
    flex-basis: 0;
  }
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: end;
  flex-grow: 1;
  flex-basis: 0;
`;

const CenterItemContainerDesktop = styled.div<ICenterItemContainerDesktop>`
  ${(p): string => p.delayedComponent ? 'display: none !important' : ''};

  @media ${mediaQueries.laptop} {
    display: flex;
    justify-content: center;
  }
`;

const PageHeaderContent = styled(ContentContainerPageHeader)`
  ${spacingTopBottom(4)};

  @media ${mediaQueries.tablet} {
    ${spacingTopBottom(2)};
    height: 88px;
  }

  @media ${mediaQueries.desktop} {
    display: flex;
    justify-content: space-between;
  }
`;

const GlobalSearchWrapper = styled.div<ISearchInHeaderProps>`
  ${spacingLeft(2)};
  padding-right: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  height: ${(p): string => p.mainSearchVisible ? '0' : '100%'};
  opacity: ${(p): number => p.mainSearchVisible ? 0 : 1};
  pointer-events: ${(p): string => p.mainSearchVisible ? 'none' : 'default'};
  transition: ${(p): string => p.mainSearchVisible ? 'height 350ms, opacity 350ms' : 'height 300ms, opacity 300ms'};

  @media ${mediaQueries.mobile} {
    ${spacingRight(2)};
  }

  @media ${mediaQueries.tablet} {
    ${spacingLeft(1)};
    height: 100%;
  }

  @media ${mediaQueries.laptop} {
    ${spacingRight(1)};
    ${spacingLeft(1)};
  }
`;

const CenterItem = styled.div`
  display: none;

  @media ${mediaQueries.tablet} {
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
  }
`;

const PageHeaderContainer = styled.header`
  position: sticky;
  z-index: 105;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${theme.white};

  @media print {
    height: 100px;
  }

  @media print {
    position: relative;
  }
`;

const PageHeaderInner = styled.div`
  background-color: ${theme.isInverted ? theme.backgroundDark : theme.backgroundLight};

  @media print {
    background-color: transparent;
  }
`;

const PageMenuLeft = styled.div`
  display: none;

  @media ${mediaQueries.laptop} {
    display: flex;
  }

  @media print {
    display: none;
  }
`;

const PageMenuRight = styled.div`
  margin-right: 0;
  ${spacingLeft(2)};
  display: flex;
  justify-content: center;

  @media ${mediaQueries.laptop} {
    display: none;
  }

  @media print {
    display: none;
  }
`;

const AvailabilityCheckContainer = styled(WidgetContainer)`
  display: none;

  @media all and (min-width: 1320px) {
    ${spacingRight(4)};
    display: block;
  }

  @media all and (min-width: 2000px) {
    ${spacingRight(0)};
    margin-left: ${spacingCalculator(3)};
  }

  @media print {
    display: none;
  }
`;

const DigandoLogoWrapperMobile = styled.div<ISearchInHeaderProps>`
  line-height: 1;

  img {
    width: auto;
    height: 20px;
    vertical-align: bottom;
  }

  @media ${mediaQueries.laptop} {
    display: none;
  }

  @media print {
    padding-left: 0;
  }
`;

const LaptopWrapper = styled.div`
  color: ${theme.isInverted ? theme.white : theme.black};

  @media all and (min-width: 1800px) {
    display: none;
  }
`;

const LogoShortWrapper = styled.div<ISearchInHeaderProps>`
  height: 100%;
  opacity: 1;
  transition: height 0ms 900ms;
`;

const DesktopWrapper = styled.div`
  display: none;

  @media all and (min-width: 1800px) {
    display: inline-block;
    ${spacingRight(1)};
    color: ${theme.isInverted ? theme.white : theme.black};
  }
`;

const DigandoLogoWrapperDesktop = styled.div`
  max-height: 30px;
  display: none;

  @media ${mediaQueries.laptop} {
    display: flex;
    margin-top: 0;
    ${spacingLeft(4)};

    img {
      max-width: fit-content;
      height: 30px;
      vertical-align: bottom;
    }
  }

  @media print {
    padding-left: 0;
  }
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${theme.black};
  height: auto;
  ${spacingBottom(1)};
  ${spacingTop(1)};
  padding-left: 16px;
  padding-right: 16px;

  @media all and (max-width: 361px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media all and (max-width: 321px) {
    padding-left: 6px;
    padding-right: 6px;
  }

  div {
    @media all and (max-width: 450px) {
      margin-left: 0;
    }
  }

  @media all and (max-width: 450px) {
    justify-content: space-between;
  }

  @media ${mediaQueries.tablet} {
    display: none;
  }

  @media print {
    display: none;
  }
`;
export default React.memo(PageHeader, () => true);
