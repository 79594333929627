import styled from '@emotion/styled';
import {
  FontSecondaryRegular,
  spacingLeft,
  spacingLeftRight,
  spacingTopBottom,
  theme,
} from '@digando/react-component-library';
import { IDigandoStylingButtonProps } from './Button';

export const ButtonWrapper = styled.div<IDigandoStylingButtonProps>`
  .button {
    box-sizing: border-box;
    font: revert;
    ${FontSecondaryRegular};

    ${(p): string => {
      if (true === p.inverted) {
        return `
          background-color: ${theme.primaryColorInverted};
        `;
      }

      return `
        background-color: ${theme.primaryColor};
      `;
    }}

    border: 1px solid ${theme.primaryColor};
    border-radius: 16.5px;
    transition: background-color 0.3s ease;
    cursor: pointer;
    font-size: 20px;
    text-decoration: none;
    text-align: center;

    ${(p): string => {
      return spacingTopBottom(1.5);
    }}

    ${(p): string => {
      if ('small' === p.spacing) {
        return spacingLeftRight(3);
      }

      return spacingLeftRight(8);
    }}

    &:hover {
      background-color: ${theme.primaryHoverColor};

      * {
        color: ${theme.white};
      }
    }

    &[disabled] {
      background-color: ${theme.lightGrey};
      border-color: ${theme.lightGrey};
      color: ${theme.darkGrey};
      cursor: not-allowed;
    }
  }

  * {
    ${(p): string => {
      if (true === p.inverted) {
        return `
          color: ${theme.black};
        `;
      }

      return `
        color: ${theme.white};
      `;
    }}

    font-size: 20px;
  }

  &.bordered {
    * {
      color: ${theme.primaryColor};
    }

    .button {
      background-color: ${theme.white};
      border: 1px solid ${theme.primaryColor};

      &[disabled] {
        border-color: ${theme.lightGrey};
        cursor: not-allowed;

        .button-text {
          color: ${theme.darkGrey};
        }
      }
    }

    .button-loader {
      pointer-events: none;

      .ant-spin-dot-item {
        background-color: ${theme.primaryColor};
      }
    }

    &.full-width {
      width: 100%;

      .button {
        width: 100% !important;
      }
    }

    &:hover {
      border-color: ${theme.primaryHoverColor};

      * {
        color: ${theme.primaryHoverColor};
      }
    }
  }

  &.small {
    * {
      font-size: 17px;
      line-height: 21px;
    }

    .button {
      padding-top: 6px;
      padding-bottom: 6px;
      border-radius: 11px;
    }
  }

  &.ghost {
    .button {
      background-color: transparent;
      color: ${theme.primaryColor};

      &:hover {
        color: ${theme.primaryHoverColor};

        * {
          color: ${theme.primaryHoverColor};
        }
      }
    }

    &:hover {
      svg {
        fill: ${theme.primaryHoverColor};
      }
    }

    * {
      color: ${theme.primaryColor};
    }
  }

  .button {
    position: relative;
  }

  .button-loader {
    position: absolute;
    right: 8px;
    top: 50%;
    ${spacingLeft(0)};
    transform: translateY(-40%) scale(0);
    transform-origin: center center;
    transition: all 0.3s ease;
    overflow: hidden;
    width: 45px;

    .ant-spin-dot-item {
      background-color: ${theme.white};
    }
  }

  &.loading {
    .button-loader {
      transform: translateY(-40%) scale(1);
    }
  }
`;
