import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const themeName = publicRuntimeConfig.theme ?? 'default';
const currentTheme = await import((`./${themeName}/themed-variables`));

export const Fonts = {
  fontPrimary: currentTheme.themeVariables.fonts.fontPrimary,
  fontSecondary: currentTheme.themeVariables.fonts.fontSecondary,
}

export enum FontPrimaryWeights {
  REGULAR = 400,
  SEMI_BOLD = 600,
  BOLD = 700,
}

export enum FontPrimaryStyle {
  REGULAR_NORMAL = 'normal',
  SEMI_BOLD_NORMAL = 'normal',
}

export enum FontSecondaryWeights {
  LIGHT= 300,
  REGULAR = 400,
  SEMI_BOLD = 600,
}

export enum FontSecondaryStyle {
  LIGHT_NORMAL = 'normal',
  REGULAR_NORMAL = 'normal',
  REGULAR_ITALIC = 'italic',
  SEMI_BOLD_NORMAL = 'normal',
}

export const FontPrimaryRegular = `font-family: ${Fonts.fontPrimary};font-weight: ${FontPrimaryWeights.REGULAR};font-style: ${FontPrimaryStyle.REGULAR_NORMAL}; font-display: optional;`;

export const FontPrimarySemiBold = `font-family: ${Fonts.fontPrimary};font-weight: ${FontPrimaryWeights.SEMI_BOLD};font-style: ${FontPrimaryStyle.SEMI_BOLD_NORMAL}; font-display: optional;`;

export const FontPrimaryBold = `font-family: ${Fonts.fontPrimary};font-weight: ${FontPrimaryWeights.BOLD};font-style: ${FontPrimaryStyle.REGULAR_NORMAL}; font-display: optional;`;

export const FontSecondaryLight = `font-family: ${Fonts.fontSecondary};font-weight: ${FontSecondaryWeights.LIGHT};font-style: ${FontSecondaryStyle.LIGHT_NORMAL}; font-display: optional;`;

export const FontSecondaryRegular = `font-family: ${Fonts.fontSecondary};font-weight: ${FontSecondaryWeights.REGULAR};font-style: ${FontSecondaryStyle.REGULAR_NORMAL}; font-display: optional;`;

export const FontSecondaryRegularItalic = `font-family: ${Fonts.fontSecondary};font-weight: ${FontSecondaryWeights.REGULAR};font-style: ${FontSecondaryStyle.REGULAR_ITALIC}; font-display: optional;`;

export const FontSecondarySemiBold = `font-family: ${Fonts.fontSecondary};font-weight: ${FontSecondaryWeights.SEMI_BOLD};font-style: ${FontSecondaryStyle.SEMI_BOLD_NORMAL}; font-display: optional;`;
