import { SetRecoilState, isRecoilValue } from 'recoil';
import { productSearchAtoms } from './product-search';
import { productConfiguratorAtoms } from './product-configurator';
import { globalSearchAtoms } from './global-search';
import dayjs from 'dayjs';
import { countriesState } from './states';
import {
  blogOverviewHeaderState,
  blogOverviewNewPostsState,
  blogOverviewPostsState,
} from '../../components/blog/store/blog-overview';

const allAtoms = {
  ...productSearchAtoms,
  ...productConfiguratorAtoms,
  ...globalSearchAtoms,
  ...productConfiguratorAtoms,
  countries: countriesState,
  blogOverviewHeaderState,
  blogOverviewNewPostsState,
  blogOverviewPostsState,
};

export const initializeRecoilState =
  // eslint-disable-next-line
  (data: any) =>
    ({ set }: { set: SetRecoilState }): void => {
      if (data) {
        Object.keys(data).forEach((key: string) => {
          let value = data[key];
          const atom = allAtoms[key as keyof typeof allAtoms];

          if ('filterValues' === key) {
            value = {
              ...value,
              dateRange: {
                gte: dayjs(value.dateRange.gte),
                lte: dayjs(value.dateRange.lte),
              },
            };
          }

          isRecoilValue(atom) && set(atom, value);
        });
      }
    };
