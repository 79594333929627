import React, { FC, useRef } from 'react';
import { LoginStates, useAuthContext } from '../../../store/auth.context';
import {
  Icon,
  mediaQueries,
  spacingLeft,
  spacingRight, theme,
  useHasUserScrolled,
  useOutsideClick,
} from '@digando/react-component-library';
import { WidgetDescription } from '../styles/widget-description';
import styled from '@emotion/styled';
import { WidgetContainer } from '../styles/widget-container';
import { useRouter } from 'next/router';
import { useNavigationContext } from '../../../store/navigation.context';
import { useGlobalSearchValues } from '../../../store/recoil/global-search';
import { DrawerStep } from '../../sidebar/interfaces/drawer.interfaces';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';

const LoggedInMenu = dynamic(() => import('./logged-in-menu'));
const Dropdown = dynamic(() => import('antd').then((mod) => mod.Dropdown), {
  ssr: false,
});

interface ISearchInHeaderProps {
  mainSearchVisible: boolean;
}

type ProfileItemProps = {
  small: boolean;
}

export const ProfileItem: FC<ProfileItemProps> = ({ small }) => {
  const closeDropDownMenuRef = useRef<HTMLDivElement>(null);
  const { loginState, setRedirectLink, setIsSignInVisible, setSignInStep } = useAuthContext();
  const { isUserDropdownMenuVisible, setIsUserDropdownMenuVisible } = useNavigationContext();
  const { mainSearchVisible } = useGlobalSearchValues();
  const router = useRouter();
  const [t] = useTranslation(['common']);

  useOutsideClick(closeDropDownMenuRef, () => {
    setIsUserDropdownMenuVisible(false);
  });

  useHasUserScrolled(closeDropDownMenuRef, () => {
    setIsUserDropdownMenuVisible(false);
  });

  const signInRedirect = (event: React.MouseEvent, link: string): void => {
    setRedirectLink(link);
    onSignInClick(event);
  };

  const onSignInClick = (event: React.MouseEvent): void => {
    event.preventDefault();

    setSignInStep(DrawerStep.SIGN_IN);
    setIsSignInVisible(true);
  };

  return (
    <SignInContainer
      mainSearchVisible={mainSearchVisible}
      ref={closeDropDownMenuRef}
    >
      {LoginStates.LOGGED_IN === loginState ? (
        <DropdownMenu
          dropdownRender={() => <LoggedInMenu />}
          placement={'bottomLeft'}
          overlayClassName={'page-header-menu'}
          open={isUserDropdownMenuVisible}
          trigger={[]}
        >
          <button
            onClick={(): void => {
              setIsUserDropdownMenuVisible(!isUserDropdownMenuVisible);
            }}
          >
            <Icon icon={'user'} size={'30px'} />
            <WidgetDescription isHidden={false}>
              <LaptopWrapper> {false === small && t('common:my-profile')}</LaptopWrapper>
              <DesktopWrapper>{t('common:my-profile')}</DesktopWrapper>
            </WidgetDescription>
          </button>
        </DropdownMenu>
      ) : (
        <button
          onClick={(e): void => signInRedirect(e, router.asPath)}
          data-e2e-id={'DigandoSignInHeaderButton'}
          aria-label={'Login'}
        >
          <Icon icon={'user'} size={'30px'} color={theme.isInverted ? theme.white : theme.black} />
          <WidgetDescription isHidden={false}>
            {false === small && t('common:sign-in')}
          </WidgetDescription>
        </button>
      )}
    </SignInContainer>
  );
};

const DropdownMenu = styled(Dropdown)`
  position: relative;
  z-index: 110;
`;

const SignInContainer = styled(WidgetContainer)<ISearchInHeaderProps>`
  ${spacingLeft(3)};

  > a {
    .icon {
      transition: ${theme.textColorHoverTransition};

      svg {
        transition: ${theme.textColorHoverTransition};
        fill: ${theme.isInverted ? theme.white : theme.black};
      }

      @media ${mediaQueries.tablet} {
        font-size: 30px;
      }
    }

    width: 100%;
  }

  @media ${mediaQueries.laptopL} {
    margin-left: 0;
  }

  @media ${mediaQueries.desktop} {
    margin-left: 3.4vw;
    justify-content: end;
    ${spacingRight(0)};
  }

  @media print {
    display: none;
  }
`;

const LaptopWrapper = styled.div`
  color: ${theme.isInverted ? theme.white : theme.black};

  @media all and (min-width: 1800px) {
    display: none;
  }
`;

const DesktopWrapper = styled.div`
  display: none;

  @media all and (min-width: 1800px) {
    display: inline-block;
    ${spacingRight(1)};
    color: ${theme.isInverted ? theme.white : theme.black};
  }
`;
